import React from "react";
import { useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Snackbar, TextField, Button } from "@material-ui/core";
import GenericTemplate from "../templates/General";
import { startTask } from "../api/request";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: 20,
      marginRight: 10,
    },
    snackBar: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface IAddFormProps {}

const AddForm: React.FunctionComponent<IAddFormProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  // Text Field States
  const [url, setUrl] = React.useState<string>("");
  const [savePath, setSavePath] = React.useState<string>("/downloads/");

  // Snackbar States
  const [errorSnackBarOpen, setErrorSnackBarOpen] =
    React.useState<boolean>(false);

  const closeErrorSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackBarOpen(false);
  };

  const [successSnackBarOpen, setSuccessSnackBarOpen] =
    React.useState<boolean>(false);

  const closeSuccessSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackBarOpen(false);
  };

  // API Call
  const submitHandler = () => {
    startTask({ url: url, save_path: savePath })
      // On Success
      .then((data) => {
        console.log(data);
        setSuccessSnackBarOpen(true);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      })
      // On Fail
      .catch((err) => {
        console.log(err);
        setErrorSnackBarOpen(true);
      });
  };

  // Return to main page
  const cancelHandler = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={errorSnackBarOpen}
        autoHideDuration={3000}
        onClose={closeErrorSnackBar}
      >
        <Alert severity="error" variant="outlined">
          <AlertTitle>Error</AlertTitle>
          Task start request failed
        </Alert>
      </Snackbar>

      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={successSnackBarOpen}
        autoHideDuration={3000}
        onClose={closeSuccessSnackBar}
      >
        <Alert severity="success" variant="outlined">
          <AlertTitle>Success</AlertTitle>
          Task start request has sent
        </Alert>
      </Snackbar>

      <GenericTemplate title="Add Web Item">
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <TextField
              id="url"
              label="URL"
              placeholder="https://...."
              variant="outlined"
              value={url}
              fullWidth
              required
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="save_path"
              label="Save Path"
              placeholder="/downloads/"
              variant="outlined"
              value={savePath}
              fullWidth
              required
              onChange={(e) => {
                setSavePath(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={submitHandler}
            >
              Submit
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              onClick={cancelHandler}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </GenericTemplate>
    </React.Fragment>
  );
};

export default AddForm;
