import React from "react";
import { Grid } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GenericTemplate from "../templates/General";
import WebItem from "../components/WebItem";
import { useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { getCurrentTasks } from "../api/request";
import { TasksResponse } from "../api/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
  })
);

const REFRESH_INTERVAL = 5000;

const HomePage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const goToAddPage = () => {
    history.push("/add");
  };

  const [currentTasks, updateTasks] = React.useState<TasksResponse>();

  React.useEffect(() => {
    const currentTaskRefresh = () => {
      getCurrentTasks().then((currentTasks) => {
        updateTasks(currentTasks);
      });
    };
    // First Access
    currentTaskRefresh();

    // Repeat every REFRESH_INTERVAL seconds
    const timer = setInterval(currentTaskRefresh, REFRESH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getDateString = (timeString: string): string => {
    let d = new Date(timeString);
    return d.toLocaleDateString() + " " + d.toLocaleTimeString();
  };

  return (
    <React.Fragment>
      <GenericTemplate title="Home">
        <Grid container spacing={2} direction="column">
          {currentTasks?.items.map((task) => (
            <Grid item>
              <WebItem
                id={task.id}
                save_path={task.save_path}
                added_on={getDateString(task.added_on)}
                progress={Number(task.progress) * 100}
                status={task.state}
                upspeed={(Number(task.upspeed) / 1024 / 1024).toFixed(2)}
                downspeed={(Number(task.dlspeed) / 1024 / 1024).toFixed(2)}
              ></WebItem>
            </Grid>
          ))}
        </Grid>
      </GenericTemplate>
      <Fab
        className={classes.fab}
        color="primary"
        size="large"
        aria-label="add"
        onClick={goToAddPage}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default HomePage;
