const awsconfig = {
    Auth: {
        region: 'ap-northeast-1',
        identityPoolId: 'ap-northeast-1:1553fff4-9b7d-4db5-9e2c-5c95683cce49',
        userPoolId: 'ap-northeast-1_CAvIp9GMN',
        userPoolWebClientId: '2miaq3boqiqq620ol4jsdbeiio'
    },
    API: {
        endpoints: [
            {
                name: 'task_api',
                region: "ap-northeast-1",
                endpoint:
                    'https://xyrwylbyq6.execute-api.ap-northeast-1.amazonaws.com/dev',
            },
        ],
    },
}

export default awsconfig
