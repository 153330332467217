import { createMuiTheme } from "@material-ui/core/styles";
import { indigo, green } from "@material-ui/core/colors";

export default createMuiTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    type: "dark",
    primary: {
      main: green[600],
    },
    secondary: {
      main: indigo[300],
    },
  },
});
