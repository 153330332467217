import {
  TasksResponse,
  TaskStartResponse,
  TaskStartRequest,
  TaskStopRequest,
  TaskStopResponse,
} from "./types";
import awsconfig from "../awsconfig";
import Amplify, { API } from "aws-amplify";
Amplify.configure(awsconfig);

const apiError = (identifier: string, response: any) => {
  const desc = "invalid request: " + identifier;
  console.log(desc);
  console.log({ response });
  return Error(desc);
};

export async function getCurrentTasks(): Promise<TasksResponse> {
  const apiName = "task_api";
  const path = "/tasks";
  const init = { response: true };
  const res = await API.get(apiName, path, init);
  const response = JSON.parse(JSON.stringify(res.data)) as
    | TasksResponse
    | undefined;
  if (response === undefined) {
    throw apiError("listTaskStatus", response);
  }
  return response;
}

export async function startTask(
  task_start_request: TaskStartRequest
): Promise<TaskStartResponse> {
  const apiName = "task_api";
  const path = "/tasks";
  const init = {
    response: true,
    body: {
      url: task_start_request.url,
      save_path: task_start_request.save_path,
    },
  };
  const res = await API.post(apiName, path, init);
  const response = JSON.parse(JSON.stringify(res.data)) as
    | TaskStartResponse
    | undefined;
  if (response === undefined) {
    throw apiError("startTasks", response);
  }
  return response;
}

export async function stopTask(
  task_stop_request: TaskStopRequest
): Promise<TaskStopResponse> {
  const apiName = "task_api";
  const path = "/task";
  const init = {
    response: true,
    body: {
      id: task_stop_request.id,
    },
  };
  const res = await API.del(apiName, path, init);
  const response = JSON.parse(JSON.stringify(res.data)) as
    | TaskStopResponse
    | undefined;
  if (response === undefined) {
    throw apiError("stopTask", response);
  }
  return response;
}
