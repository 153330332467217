import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Collapse,
  Button,
  CardHeader,
  IconButton,
  CardActions,
  DialogTitle,
  DialogActions,
  Dialog,
  Menu,
  MenuItem,
} from "@material-ui/core";
import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import clsx from "clsx";
import { stopTask } from "../api/request";

interface IWebItemProps {
  id: string;
  save_path: string;
  added_on: string;
  progress: number;
  status?: string;
  upspeed: string;
  downspeed: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "",
    },
    details: {
      display: "flex",
      flexDirection: "row",
    },
    expand: {
      transform: "rotate(0deg)",
      // marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function stateIconMapping(state: string | undefined) {
  if (state === "downloading") {
    return <CloudDownloadIcon color={"secondary"} />;
  } else if (state === "failed") {
    return <ErrorIcon color={"error"} />;
  } else if (state === "completed") {
    return <CheckCircleIcon color={"primary"} />;
  } else {
    return <HourglassEmptyIcon />;
  }
}

const WebItem: React.FunctionComponent<IWebItemProps> = (props) => {
  const classes = useStyles();

  // Detailed Expansion toggle
  const [expanded, setExpanded] = React.useState(false);
  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  // Dialog Toggle
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDelete = () => {
    stopTask({
      id: props.id,
    })
      .then((data) => {
        console.log(data);
        setDialogOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Menu Open toggle
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardHeader
          aria-label="status"
          avatar={stateIconMapping(props?.status)}
          title={props.save_path}
          subheader={props.added_on}
          action={
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="item-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: "20ch",
                  },
                }}
              >
                <MenuItem key={1} onClick={handleDialogOpen}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
          }
        ></CardHeader>
        <CardContent>
          <LinearProgressWithLabel
            variant="determinate"
            value={props.progress}
          />
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item className={classes.details}>
              <ArrowDownwardIcon color="primary" />
              <Typography variant="body2" color="textSecondary">
                {props.downspeed} MB/sec
              </Typography>
            </Grid>
            <Grid item className={classes.details}>
              <ArrowUpwardIcon color="secondary" />
              <Typography variant="body2" color="textSecondary">
                {props.upspeed} MB/sec
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant={"subtitle2"}>ID: {props.id}</Typography>
            <Typography variant={"subtitle2"}>
              Status: {props.status}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <Dialog open={dialogOpen}>
        <DialogTitle>Are you sure to delete</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default WebItem;
