import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/Home";
import AddPage from "./pages/AddForm";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "./styles/Theme";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authenticator>
        <Router>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/add" component={AddPage} />
          </Switch>
        </Router>
      </Authenticator>
    </ThemeProvider>
  );
};

export default App;
